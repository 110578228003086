import EntityToRowMapper from './EntityToRowMapper'
import { EdgeDirection } from '../enums/EdgeDirection'
import { EdgeType } from '../enums/EdgeType'
import pluralize from 'pluralize'
import sort from 'fast-sort'
import { System } from '../enums/System'
import { Group } from '../types/Group'

export default (edges: any, entity: any, debug: boolean): { groups: Group[]; related: any } => {
  let related: any[] = []
  let groups: any[] = []

  edges.forEach((edge: any) => {
    const entityType = edge.entity.type.toLowerCase()
    const entitySource = edge.entity.source
    const edgeType = edge.type
    const direction = edge.direction
    const edgeSource = edge.source

    let group: Group = groups.find(group => {
      // Group by type and source
      const match = group.type === entityType && group.source === entitySource
      // If type is account, group by direction and edgeType too
      return entityType === 'account' ? match && group.edgeType === edgeType && direction === group.direction : match
    })

    const mappedEntity = EntityToRowMapper(edge.entity, edgeType, direction, edgeSource)
    let sortPriority = 3
    if (entityType === 'account') {
      if (direction === EdgeDirection.OUTGOING) {
        sortPriority = 1
      } else {
        sortPriority = 2
      }
    }

    if (!group) {
      let label = ''
      if (entityType === 'account') {
        // Add child or parent to accounts of the same system and type PARENT_ACCOUNT
        if (entitySource === entity.source) {
          if (edgeType === EdgeType.PARENT_ACCOUNT) {
            label = `${direction === EdgeDirection.INCOMING ? 'child' : 'parent'} `
          }
        } else {
          if (entitySource === System.SALESFORCE) {
            label = 'Sales '
          } else if (entitySource === System.SERVICE_NOW) {
            label = 'Service '
          }
        }
        label += pluralize(entityType)
      } else {
        label = pluralize(entityType)
      }

      group = {
        sortPriority,
        type: entityType,
        source: entitySource,
        edgeType,
        label,
        direction,
        rows: [mappedEntity],
      }
      groups.push(group)
    } else {
      group.rows.push(mappedEntity)
    }
  })
  groups = sort(groups).asc([r => r.sortPriority, r => r.label])
  if (debug) {
    console.error(groups)
  }
  return { groups, related }
}
