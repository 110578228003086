const EntityTableFields = {
  default: [
    { id: 'entityId', numeric: false, disablePadding: false, label: 'Entity ID' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'source', numeric: true, disablePadding: false, label: 'Source' },
  ],
  account: [
    { id: 'entityId', numeric: false, disablePadding: true },
    { id: 'accountNumber', numeric: true, disablePadding: false },
    { id: 'name', numeric: false, disablePadding: false },
    { id: 'description', numeric: false, disablePadding: false },
  ],
  case: [
    { id: 'entityId', numeric: false, disablePadding: true },
    { id: 'caseNumber', numeric: false, disablePadding: false },
    { id: 'subject', numeric: false, disablePadding: false },
    { id: 'createdDate', numeric: false, disablePadding: false },
    { id: 'priority', numeric: false, disablePadding: false },
    { id: 'status', numeric: false, disablePadding: false },
  ],
  contact: [
    { id: 'entityId', numeric: false, disablePadding: true },
    { id: 'firstName', numeric: false, disablePadding: false },
    { id: 'lastName', numeric: false, disablePadding: false },
    { id: 'email', numeric: false, disablePadding: false },
    { id: 'serviceId', numeric: false, disablePadding: false },
  ],
  asset: [
    { id: 'entityId', numeric: false, disablePadding: true },
    { id: 'name', numeric: false, disablePadding: false },
    { id: 'serialNumber', numeric: true, disablePadding: false },
  ],
  customer: [
    { id: 'entityId', numeric: false, disablePadding: true },
    { id: 'name', numeric: false, disablePadding: false },
  ],
}

export default EntityTableFields
