import deepmerge from 'deepmerge'

const Settings: any = {
  default: {
    api: {
      apiBaseUrl: 'https://api.edge-api.polarademo.com/demo',
      apiMockUrl: '/mock-api',
    },
    graphUrl: 'https://graph-viewer.edge-api.polarademo.com',
    automateUrl: 'https://atc.polarademo.com',
    defaultKeycloakIdpAlias: 'polara-okta-saml',
    keycloak: {
      app: {
        url: 'https://keycloak.polarademo.com/auth/',
        realm: 'polara',
        clientId: 'polara-edge',
      },
      initConfig: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/auth/silent-check-sso.html',
      },
    },
  },
  local: {
    api: {
      apiBaseUrl: 'https://api.edge-api.polarademo.com/demo',
    },
    graphUrl: 'https://graph-viewer.edge-api.polarademo.com',
    automateUrl: 'http://localhost:3000',
    // graphUrl: 'http://localhost:3000',
    keycloak: {
      app: {
        clientId: 'polara-edge-local',
      },
    },
  },
}
const Config: any = deepmerge(Settings.default, process.env.REACT_APP_ENV ? Settings[process.env.REACT_APP_ENV] || {} : {})
export default Config
