const replaceId = (path: string, id?: string) => {
  return id ? path.replace(':id', id) : path
}

export const Paths = {
  home: '/home',
  login: '/login',
  events: '/events',
  event: (id?: string) => {
    return replaceId('/event/:id', id)
  },
  entity: (id?: string) => {
    return replaceId('/entity/:id', id)
  },
  logs: (id?: string) => {
    return replaceId('/entity/:id/logs', id)
  },
}
