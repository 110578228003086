import faker from 'faker'
import { EdgeDirection } from '../enums/EdgeDirection'
import { EdgeType } from '../enums/EdgeType'
import { System } from '../enums/System'
import { MappedEntity } from '../types/MappedEntity'

export default (entity: any, edgeType?: EdgeType, edgeDirection?: EdgeDirection, edgeSource?: System): MappedEntity => ({
  ...entity.properties,
  name: entity.name,
  entityId: entity.entityId,
  source: entity.source,
  type: entity.type.toLowerCase(),
  serviceId: faker.random.boolean() && faker.random.number(),
  edgeType,
  edgeDirection,
  edgeSource,
})
