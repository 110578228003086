import Snackbar from '@material-ui/core/Snackbar'
import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useSelector } from 'react-redux'
import { closeSnackbar, store } from '../common/Store'
import MuiAlert from '@material-ui/lab/Alert'
import { SnackbarData } from '../types/SnackbarData'
import { SnackbarSeverity } from '../enums/SnackbarSeverity'

const Alert = (props: any) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default () => {
  const data: SnackbarData = useSelector((state: any) => state.snackbar)

  const handleCloseSnackBar = () => {
    store.dispatch(closeSnackbar())
  }

  return (
    <Snackbar
      open={data.open}
      autoHideDuration={4000}
      onClose={handleCloseSnackBar}
      action={
        <>
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackBar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    >
      <Alert onClose={handleCloseSnackBar} severity={data.severity || SnackbarSeverity.INFO}>
        {data.message}
      </Alert>
    </Snackbar>
  )
}
