import { SERVICE_NOW_CASE_URL, SFDC_URL } from './Constants'
import { System } from '../enums/System'

export const getExternalSystemUrl = (entity: any) => {
  let url
  if (entity.source === System.SERVICE_NOW) {
    switch (entity.type) {
      case 'case':
        url = `${SERVICE_NOW_CASE_URL}${entity.entityId}`
        break
      case 'account':
        url = `https://dev85817.service-now.com/nav_to.do?uri=%2Fcustomer_account.do%3Fsys_id%3D${entity.entityId}%26sysparm_view%3Dtext_search`
        break
      case 'asset':
        url = `https://dev85817.service-now.com/nav_to.do?uri=%2Falm_asset.do%3Fsys_id%3D${entity.entityId}%26sysparm_view%3Dcase%26sysparm_record_target%3Dalm_asset%26sysparm_record_row%3D1%26sysparm_record_rows%3D1%26sysparm_record_list%3Ddisplay_nameSTARTSWITHnewest%255EORDERBYDESCsys_updated_on`
        break
    }
  } else if (entity.source === System.SALESFORCE) {
    url = `${SFDC_URL}/${entity.entityId}/view`
  }
  return url
}
