import { System } from '../enums/System'

export const EMPTY_FIELD = '-'
export const HIDDEN_FIELD = 'hidden'
export const DATE_TIME_FORMAT = `MMM Do YYYY, HH:mm`
export const LOG_DATE_TIME_FORMAT = `YYYY-MM-DD HH:mm`

export const SYSTEMS_LABELS: { [key: string]: string } = {
  [System.SERVICE_NOW]: 'ServiceNow',
  [System.SALESFORCE]: 'Salesforce',
  [System.POLARA]: 'Polara',
}
export const OBJECT_TYPES = ['Account', 'Asset', 'Case', 'Contact', 'ContactGroup', 'Customer']
export const NEW_ENTITY_TYPES = ['ContactGroup', 'Customer']
export const LINK = 'link'
export const EDGE = 'edge'

export const SFDC_URL = 'https://casespoc-dev-ed.lightning.force.com/lightning/r'
export const SERVICE_NOW_CASE_URL = 'https://dev85817.service-now.com/nav_to.do?uri=%2Fsn_customerservice_case.do%3Fsys_id%3D'

export const EXCLUDE_PARENT_ACCOUNTS = true
