import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TableCell, withStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import FieldLabel from '../../../../components/FieldLabel'

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const useStyles = makeStyles(theme => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

const EntityTableHead = props => {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, type, columns } = props
  const classes = useStyles()
  const editMode = useSelector(state => state.editMode)

  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {editMode && (
          <StyledTableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </StyledTableCell>
        )}
        {(columns[type] || columns['default']).map(column => (
          <StyledTableCell
            key={column.id}
            align={column.numeric ? 'right' : 'left'}
            padding={editMode ? (column.disablePadding ? 'none' : 'default') : 'default'}
            sortDirection={orderBy === column.id ? order : false}
          >
            <TableSortLabel
              style={{ color: '#FFF' }}
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id)}
            >
              <FieldLabel type={type} id={column.id} />
              {orderBy === column.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        <StyledTableCell align="right">{/* menu */}</StyledTableCell>
      </TableRow>
    </TableHead>
  )
}

EntityTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default EntityTableHead
