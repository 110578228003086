import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card/Card'
import EventsTable from './components/EventsTable'
import { getEvents } from '../../services/Api'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { CardContent, Collapse, Divider } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { SYSTEMS_LABELS } from '../../common/Constants'
import TextField from '@material-ui/core/TextField/TextField'
import { Option } from '../../types/Option'
import moment from 'moment'
import { System } from '../../enums/System'

const periods: Option[] = [
  { label: 'Last 6 Hours', amount: 6, unit: 'hours' },
  { label: 'Today', amount: 1, unit: 'days' },
  { label: 'Last Week', amount: 7, unit: 'days' },
  { label: 'Last Month', amount: 1, unit: 'months' },
]

export default () => {
  const systems: any = ['S3', System.SALESFORCE, System.SERVICE_NOW]
  const [system, setSystem] = useState<string>('ALL')
  const [period, setPeriod] = useState<string>('Today')
  const [events, setEvents] = useState<any[]>([])
  const [filteredEvents, setFilteredEvents] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(true)
    getEvents()
      .then(res => {
        setEvents(res.data)
        setFilteredEvents(res.data)
      })
      .finally(() => setLoading(false))
  }, [])

  const handleSystemChange = (event: any) => {
    const value = event.target.value
    setSystem(value)
    setFilteredEvents(value === 'ALL' ? events : events.filter(e => e.source === value))
  }

  const handlePeriodChange = (event: any) => {
    const value = event.target.value
    setPeriod(value)
    const option = periods.find(p => p.label === value)
    if (option) {
      setFilteredEvents(events.filter(e => moment(e.date) > moment().subtract(option.amount, option.unit)))
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant={'h5'}>Events</Typography>
          </CardContent>
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  disabled={loading}
                  select
                  placeholder="Period"
                  onChange={handlePeriodChange}
                  size={'small'}
                  label="Period"
                  variant="outlined"
                  value={period}
                  fullWidth
                >
                  {periods.map((option, i) => (
                    <MenuItem key={i} value={option.label} dense>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled={loading}
                  select
                  placeholder="Source System"
                  onChange={handleSystemChange}
                  size={'small'}
                  label="Source System"
                  variant="outlined"
                  value={system}
                  fullWidth
                >
                  <MenuItem value="ALL" dense>
                    All
                  </MenuItem>
                  {systems.map((option: any) => (
                    <MenuItem key={option} value={option} dense>
                      {SYSTEMS_LABELS[option] || option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        {loading && (
          <Typography align={'center'} component={'div'}>
            <CircularProgress />
          </Typography>
        )}
        <Collapse in={!loading}>
          <Card>
            <EventsTable rows={filteredEvents} />
          </Card>
        </Collapse>
      </Grid>
    </Grid>
  )
}
