import React, { useEffect, useMemo, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { fade, makeStyles } from '@material-ui/core/styles'
import throttle from 'lodash/throttle'
import { searchEntities } from '../../services/Api'
import { useHistory } from 'react-router-dom'
import { Paths } from '../../common/Paths'
import { SYSTEMS_LABELS } from '../../common/Constants'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { withStyles } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { useSelector } from 'react-redux'

const autocompleteStyles = makeStyles(theme => ({
  root: {
    backgroundColor: fade(theme.palette.common.white, 0.15),
    borderRadius: '5px',
  },
  clearIndicator: {
    color: '#fff',
  },
}))

const AutocompleteTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: 'white',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      border: 'none',
    },
  },
})(TextField)

const DropdownTextField = withStyles({
  root: {
    backgroundColor: 'rgb(255,255,255,0.15)',
    borderRadius: '3px',
    marginLeft: '5px',
    width: '200px',
    fontSize: '14px',
    '& label': {
      color: 'rgb(255,255,255,0.4)',
      fontSize: '14px',
    },
    '& .MuiInputBase-input': {
      color: 'white',
    },
    '& label.Mui-focused': {
      display: 'none',
    },
    '& label.MuiFormLabel-filled': {
      display: 'none',
    },
    '& fieldset': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      border: 'none',
    },
  },
})(TextField)

export default () => {
  const autocompleteClasses = autocompleteStyles()
  const history = useHistory()
  const objectTypes = useSelector(state => state.objectTypes)
  const systems = useSelector(state => state.systems)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const [type, setType] = useState('')
  const [system, setSystem] = useState('')
  const [showAdvanced, setShowAdvanced] = useState(false)

  const handleChange = event => {
    setInputValue(event.target.value)
  }

  const fetch = useMemo(
    () =>
      throttle((text, type, system, callback) => {
        searchEntities(text, { type: type !== '' ? type : null, system: system !== '' ? system : null }).then(res => callback(res.data))
      }, 500),
    []
  )

  useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions([])
      return undefined
    }

    if (inputValue.length >= 2) {
      fetch(inputValue, type, system, results => {
        if (active) {
          setOptions(results || [])
        }
      })
    }

    return () => {
      active = false
    }
  }, [inputValue, type, system, fetch])

  const handleGoToEntity = entity => {
    if (entity) {
      setInputValue('')
      setShowAdvanced(false)
      setType('')
      setSystem('')
      history.push(Paths.entity(entity.entityId))
    }
  }

  const handleTypeChange = event => {
    setType(event.target.value)
  }

  const handleSystemChange = event => {
    setSystem(event.target.value)
  }

  return (
    <>
      <Autocomplete
        id="entity-search"
        style={{ width: 300, display: 'inline-flex' }}
        classes={autocompleteClasses}
        size={'small'}
        getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
        filterOptions={x => x}
        options={options}
        autoComplete
        includeInputInList
        blurOnSelect
        openOnFocus={true}
        freeSolo
        onClose={(event, reason) => {
          if (reason === 'select-option') {
            // FIXME: if duplicated names, this won't work
            handleGoToEntity(options.find(option => option.name === event.target.value))
          }
        }}
        renderInput={params => (
          <AutocompleteTextField
            size={'small'}
            placeholder="Search objects"
            variant="outlined"
            {...params}
            onChange={handleChange}
            InputProps={{
              ...params.InputProps,
              onFocus: () => {
                setShowAdvanced(true)
              },
            }}
          />
        )}
        renderOption={option => {
          const matches = match(option.name, inputValue)
          const parts = parse(option.name, matches)

          return (
            <Grid container alignItems="center" onClick={() => handleGoToEntity(option)}>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="textSecondary">
                  {SYSTEMS_LABELS[option.source]} {option.type}
                </Typography>
              </Grid>
            </Grid>
          )
        }}
      />
      <DropdownTextField
        select
        placeholder="Type"
        onChange={handleTypeChange}
        size={'small'}
        label="Type"
        variant="outlined"
        value={type}
        style={{ visibility: showAdvanced ? 'visible' : 'hidden' }}
      >
        {objectTypes.map(option => (
          <MenuItem key={option} value={option} dense>
            {option}
          </MenuItem>
        ))}
      </DropdownTextField>
      <DropdownTextField
        select
        placeholder="System"
        onChange={handleSystemChange}
        size={'small'}
        label="System"
        variant="outlined"
        value={system}
        style={{ visibility: showAdvanced ? 'visible' : 'hidden' }}
      >
        {systems.map(option => (
          <MenuItem key={option} value={option} dense>
            {SYSTEMS_LABELS[option]}
          </MenuItem>
        ))}
      </DropdownTextField>
    </>
  )
}
