import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import EntitySearchTableHead from './EntitySearchTableHead'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core'
import { addEdge, addLink } from '../../services/Api'
import { openSnackbar, store, updateEntityRelated } from '../../common/Store'
import { useSelector } from 'react-redux'
import { LINK } from '../../common/Constants'
import EntityToRowMapper from '../../common/EntityToRowMapper'
import FieldValue from '../FieldValue'
import { SnackbarSeverity } from '../../enums/SnackbarSeverity'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
}))

const CustomButton = withStyles(theme => ({
  root: {
    height: '20px',
    label: {
      height: '20px',
    },
  },
}))(Button)

const columns = [
  { id: 'entityId', numeric: false, disablePadding: false },
  { id: 'name', numeric: false, disablePadding: false },
  { id: 'type', numeric: false, disablePadding: false },
  { id: 'source', numeric: false, disablePadding: false },
]

export default ({ rows = [], type, entity, refreshEntity, updateRows }) => {
  const classes = useStyles()
  const objectsModal = useSelector(state => state.objectsModal)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)
  const entityRelated = useSelector(state => state.entity.related)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleLinkClick = targetEntityRow => {
    const functionCall = objectsModal.type === LINK ? addLink : addEdge
    setLoading(true)
    functionCall({ startId: entity.entityId, endId: targetEntityRow.entityId })
      .then(res => {
        store.dispatch(openSnackbar({ message: `${targetEntityRow.name} linked to ${entity.name}`, severity: SnackbarSeverity.SUCCESS }))
        // FIXME entity related panel might not work due to latest changes
        if (objectsModal.type === LINK) {
          const updatedEntityRelated = [...entityRelated]
          updatedEntityRelated.push(EntityToRowMapper(res.data.endEntity))
          store.dispatch(updateEntityRelated(updatedEntityRelated))
        } else {
          refreshEntity()
        }
        const updatedRows = rows.map(row => {
          if (row.entityId === targetEntityRow.entityId) {
            row.linked = true
          }
          return row
        })
        updateRows(updatedRows)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table className={classes.table} size="small">
          <EntitySearchTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            type={type}
            columns={columns}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={index} style={row.linked ? { backgroundColor: 'rgba(0, 0, 0, 0.15)' } : {}}>
                    {columns.map((field, columnIndex) => (
                      <TableCell align={field.numeric ? 'right' : 'left'} key={columnIndex} padding={field.disablePadding ? 'none' : 'default'}>
                        <FieldValue id={field.id} value={row[field.id]} props={{ target: '_blank' }} />
                      </TableCell>
                    ))}
                    <TableCell>
                      {row.entityId && (
                        <CustomButton
                          disabled={loading || row.linked}
                          onClick={() => handleLinkClick(row)}
                          component={'a'}
                          size={'small'}
                          variant="outlined"
                        >
                          {row.linked ? 'Linked' : 'Link'}
                        </CustomButton>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 32 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  )
}
