import Toolbar from '@material-ui/core/Toolbar'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import { useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    minHeight: 'auto',
    display: 'block',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
        }
      : {
          color: theme.palette.text.primary,
        },
}))

const EntityTableToolbar = ({ numSelected, handleDeleteClick }) => {
  const deletingEntities = useSelector(state => state.deletingEntities)
  const classes = useToolbarStyles()

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 && (
        <>
          {deletingEntities ? (
            <CircularProgress size={24} style={{ marginTop: '9px' }} />
          ) : (
            <>
              <Typography color="inherit" variant="subtitle1" component="span">
                {numSelected} selected
              </Typography>
              <Tooltip title="Delete">
                <IconButton onClick={handleDeleteClick} aria-label="delete">
                  <DeleteIcon fontSize={'small'} />
                </IconButton>
              </Tooltip>
            </>
          )}
        </>
      )}
    </Toolbar>
  )
}

EntityTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

export default EntityTableToolbar
