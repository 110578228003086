import React, { useEffect, useState } from 'react'
import './App.css'
import CssBaseline from '@material-ui/core/CssBaseline'
import EntityPage from './pages/entity/EntityPage'
import TopNav from './components/layout/TopNav'
import Main from './components/layout/Main'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Paths } from './common/Paths'
import { makeMockApiServer } from './services/MockApiServer'
import { Provider } from 'react-redux'
import { enableDebug, store, storeSystems } from './common/Store'
import { getSystems } from './services/Api'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Snackbar from './components/Snackbar'
import LogsPage from './pages/logs/LogsPage'
import EventsPage from './pages/events/EventsPage'
import EventPage from './pages/events/EventPage'
import { ThemeProvider } from '@material-ui/styles'
import Theme from './common/Theme'
import Footer from './components/Footer'
import queryString from 'query-string'
import HomePage from './pages/home/HomePage'
import { KeycloakProvider } from '@react-keycloak/web'
import Keycloak from 'keycloak-js'
import Config from './config/Config'

makeMockApiServer()

// @ts-ignore
const keycloak: Keycloak.KeycloakInstance = new Keycloak(Config.keycloak.app)

export default () => {
  const query = queryString.parse(window.location.search)
  const [loading, setLoading] = useState(true)

  if (query.debug !== undefined) {
    store.dispatch(enableDebug())
  }

  useEffect(() => {
    getSystems().then(res => {
      store.dispatch(storeSystems(res.data))
      setLoading(false)
    })
  }, [])

  return (
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <KeycloakProvider keycloak={keycloak} initConfig={Config.keycloak.initConfig}>
          <>
            <Snackbar />
            <div style={{ position: 'relative', minHeight: '100vh' }}>
              <Router>
                <CssBaseline />
                <TopNav />
                <Main>
                  {loading ? (
                    <Typography align={'center'} component={'div'}>
                      <CircularProgress />
                    </Typography>
                  ) : (
                    <Switch>
                      <Route component={HomePage} exact path="/"></Route>
                      <Route component={EntityPage} exact path={Paths.entity()}></Route>
                      <Route component={LogsPage} exact path={Paths.logs()}></Route>
                      <Route component={EventsPage} exact path={Paths.events}></Route>
                      <Route component={EventPage} exact path={Paths.event()}></Route>
                    </Switch>
                  )}
                </Main>
                <Footer />
              </Router>
            </div>
          </>
        </KeycloakProvider>
      </ThemeProvider>
    </Provider>
  )
}
