import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { openSnackbar, store } from '../../common/Store'
import { getEntity } from '../../services/Api'
import EntityToRowMapper from '../../common/EntityToRowMapper'
import LogsTable from './components/LogsTable'
import Card from '@material-ui/core/Card/Card'
import faker from 'faker'
import Button from '@material-ui/core/Button'
import { Paths } from '../../common/Paths'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import EntityPageHeader from '../../components/layout/EntityPageHeader'
import Breadcrumbs from '../../components/layout/Breadcrumbs'
import { SnackbarSeverity } from '../../enums/SnackbarSeverity'

const useStyles = makeStyles(theme => ({}))

export default () => {
  const { id } = useParams()
  const classes = useStyles()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [entity, setEntity] = useState({})
  const [logs, setLogs] = useState([])

  useEffect(() => {
    getEntity(id)
      .then(res => {
        setEntity(EntityToRowMapper(res.data))
        const logs = []
        for (let i = 1; i < 50; i++) {
          logs.push({
            id: i * 23,
            createdDate: faker.date.recent(),
            event: 'Object linked with AT&T Customer',
            createdBy: 'jsmith@ibm.com',
          })
        }
        setLogs(logs)
        setLoading(false)
      })
      .catch(err => {
        store.dispatch(openSnackbar({ message: 'Error while fetching entity', severity: SnackbarSeverity.ERROR }))
        setLoading(false)
      })
  }, [id])

  const handleGoToEntity = () => {
    history.push(Paths.entity(id))
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs entity={entity} current="Logs" />
        </Grid>
        <Grid item xs={12}>
          <EntityPageHeader entity={entity}>
            {entity.type && (
              <Button onClick={handleGoToEntity} disableElevation className={classes.button} color="primary" variant="contained">
                View
              </Button>
            )}
          </EntityPageHeader>
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <Typography align={'center'} component={'div'}>
              <CircularProgress />
            </Typography>
          ) : (
            <Card>
              <LogsTable rows={logs} />
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  )
}
