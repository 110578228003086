import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Badge from '@material-ui/core/Badge'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import MenuIcon from '@material-ui/icons/Menu'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { getNotifications } from '../../services/Api'
import Moment from 'react-moment'
import { Link, useHistory } from 'react-router-dom'
import { Paths } from '../../common/Paths'
import EntitySearch from './EntitySearch'
import Button from '@material-ui/core/Button'
import Config from '../../config/Config'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useKeycloak } from '@react-keycloak/web'

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    display: 'none',
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logo: {
    verticalAlign: 'middle',
    height: '40px',
  },
}))

export default function PrimarySearchAppBar() {
  const classes = useStyles()
  const history = useHistory()
  const [keycloak] = useKeycloak()
  const [authenticated, setAuthenticated] = useState(false)
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null)
  const [notifications, setNotifications] = useState([])
  const isNotificationsMenuOpen = Boolean(notificationsAnchorEl)
  const [user, setUser] = useState(null)

  useEffect(() => {
    getNotifications().then(res => {
      setNotifications(res.data)
    })
    if (keycloak.authenticated) {
      keycloak.loadUserInfo().then(userInfo => {
        setAuthenticated(true)
        setUser(userInfo)
      })
    }
  }, [keycloak.authenticated])

  const login = () => keycloak.login({ idpHint: Config.defaultKeycloakIdpAlias })

  const handleNotificationsMenuOpen = event => {
    setNotificationsAnchorEl(event.currentTarget)
  }

  const handleNotificationsMenuClose = () => {
    setNotificationsAnchorEl(null)
  }

  const handleGoToEntity = entityId => {
    history.push(Paths.entity(entityId))
    handleNotificationsMenuClose()
  }

  const handleGoToAutomateClick = () => {
    window.location.href = Config.automateUrl
  }

  return (
    <div className={classes.grow}>
      <AppBar position="fixed">
        <Toolbar variant="dense">
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="open drawer">
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            <img alt="App logo" className={classes.logo} src={`/images/logo.png`} />
            <Button onClick={handleGoToAutomateClick} variant="text" color="inherit" style={{ color: 'white', marginLeft: '30px' }}>
              Automate
            </Button>
            <Button component={Link} variant="text" color="inherit" style={{ color: 'white' }} to={Paths.events}>
              Events
            </Button>
          </Typography>
          <div className={classes.search}>
            <EntitySearch />
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {false && (
              <IconButton aria-label="show 17 new notifications" color="inherit" onClick={handleNotificationsMenuOpen}>
                <Badge badgeContent={notifications.length} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            )}
          </div>
          <div>{authenticated && <span>{user ? user.email : null} </span>}</div>
        </Toolbar>
      </AppBar>

      {/* Notifications menu */}
      <Menu
        anchorEl={notificationsAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="notifications-menu"
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isNotificationsMenuOpen}
        onClose={handleNotificationsMenuClose}
        transitionDuration={100}
      >
        <MenuItem style={{ borderBottom: '1px solid #CCC', paddingBottom: '10px' }}>
          <Typography variant={'caption'}>Notifications</Typography>
        </MenuItem>
        {notifications.map(notification => (
          <MenuItem dense key={notification.id} onClick={() => handleGoToEntity(notification.entityId)}>
            <div>
              <Typography variant={'caption'} component="div">
                {notification.text}
              </Typography>
              <Typography variant={'caption'} color={'textSecondary'} component="div">
                <Moment fromNow>{notification.date}</Moment>
              </Typography>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
