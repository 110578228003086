import Card from '@material-ui/core/Card/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import FieldLabel from '../FieldLabel'
import { useSelector } from 'react-redux'
import FieldValue from '../FieldValue'
import { MappedEntity } from '../../types/MappedEntity'
import { EXCLUDE_PARENT_ACCOUNTS } from '../../common/Constants'

const useStyles = makeStyles(theme => ({
  editMode: {
    //  background: theme.palette.info.light,
    transition: theme.transitions.create(['background'], { duration: theme.transitions.duration.complex }),
  },
  buttons: {
    textAlign: 'right',
  },
  type: {
    textTransform: 'capitalize',
  },
  entityDetails: {
    paddingBottom: '5px',
  },
  entityFields: {
    paddingTop: '10px',
  },
}))

const fields: any = {
  default: ['name'],
  account: ['accountNumber', 'description'],
  contact: ['firstName', 'lastName', 'email'],
  asset: ['name', 'serialNumber'],
  customer: ['name'],
  case: ['subject', 'status', 'priority', 'createdDate'],
}

if (EXCLUDE_PARENT_ACCOUNTS) {
  fields.account.push('parentAccount')
}

export default ({ entity, related = [], deleteRelated, children }: { entity: MappedEntity; related: any; deleteRelated: any; children: any }) => {
  const classes = useStyles()
  const editMode = useSelector<boolean>((state: any) => state.editMode)

  return (
    <Card className={editMode ? classes.editMode : undefined}>
      <CardContent className={classes.entityDetails}>
        <Grid container>
          <Grid item xs={7}>
            <Typography variant={'h5'}>{entity.name || entity.entityId || <Skeleton width={250} />}</Typography>
            <Typography className={classes.type} variant={'body1'} color={'textSecondary'}>
              {entity.type || <Skeleton width={150} />}
            </Typography>
          </Grid>
          <Grid className={classes.buttons} item xs={5}>
            {children}
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent className={classes.entityFields}>
        <Grid container>
          {entity.type ? (
            (fields[entity.type.toLowerCase()] || fields.default).map(
              (field: string) =>
                entity[field] !== 'hidden' && (
                  <Grid item xs={3} key={field}>
                    <Typography variant={'body2'} component={'div'} style={{ fontWeight: 'bold' }}>
                      <FieldLabel type={entity.type} id={field} />
                    </Typography>
                    <FieldValue id={field} value={entity[field]} />
                  </Grid>
                )
            )
          ) : (
            <>
              <Grid item xs={3}>
                <Skeleton width="50%" />
                <Skeleton width="90%" />
              </Grid>
              <Grid item xs={3}>
                <Skeleton width="50%" />
                <Skeleton width="90%" />
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
