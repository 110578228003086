import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import { store, toggleGraphModal } from '../../../common/Store'
import { makeStyles } from '@material-ui/core/styles'
import { SYSTEMS_LABELS } from '../../../common/Constants'
import { capitalize } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Config from '../../../config/Config'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'

const useStyles = makeStyles(theme => ({
  iframe: {
    width: '100%',
  },
  content: {
    padding: 0,
  },
  loading: {
    marginTop: '100px',
  },
}))

const IFRAME_DEFAULT_HEIGHT = 150

export default ({ entity, open }) => {
  const classes = useStyles()
  const [iframeHeight, setIframeHeight] = useState(IFRAME_DEFAULT_HEIGHT)
  const [iframeVisibility, setIframeVisibility] = useState('hidden')

  const listener = e => {
    try {
      const data = JSON.parse(e.data)
      if (data.id === 'graph-view-loaded' && !window.graphViewIframeLoaded) {
        window.graphViewIframeLoaded = true
        setIframeHeight(data.height * 1.1)
        setIframeVisibility('visible')
      }
    } catch (e) {}
  }

  useEffect(() => {
    window.addEventListener('message', listener)
    return () => {
      window.removeEventListener('message', listener)
    }
  }, [])

  const handleCloseClick = () => {
    store.dispatch(toggleGraphModal())
    window.graphViewIframeLoaded = false
    setIframeVisibility('hidden')
    setIframeHeight(IFRAME_DEFAULT_HEIGHT)
  }

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="lg" fullWidth aria-labelledby="execution-dialog-title" open={open}>
      <DialogTitle id="execution-dialog-title">
        {entity.name + ' '}
        <Typography variant={'caption'}>
          {SYSTEMS_LABELS[entity.source]} {capitalize(entity.type || '')}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content} dividers>
        {iframeVisibility === 'hidden' && (
          <Typography className={classes.loading} align={'center'} component={'div'}>
            <CircularProgress />
          </Typography>
        )}
        {entity && entity.entityId && (
          <iframe
            frameBorder={0}
            title="Entity Graph View"
            className={classes.iframe}
            src={`${Config.graphUrl}/${entity.entityId}?embed=true`}
            height={iframeHeight}
            style={{ visibility: iframeVisibility }}
          ></iframe>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseClick} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
