import React, { useCallback, useEffect, useState } from 'react'
import { getEntity } from '../../services/Api'
import Grid from '@material-ui/core/Grid'
import { useHistory, useParams } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSelector } from 'react-redux'
import EntitySearchModal from '../../components/entity-search-modal/EntitySearchModal'
import { EDGE, EXCLUDE_PARENT_ACCOUNTS, HIDDEN_FIELD, SYSTEMS_LABELS } from '../../common/Constants'
import {
  openObjectsModal,
  openSnackbar,
  store,
  toggleEditMode,
  toggleGraphModal,
  updateEntityRelated,
  updateEntityGroups,
  updateSelectedEntities,
} from '../../common/Store'
import EntityToRowMapper from '../../common/EntityToRowMapper'
import Button from '@material-ui/core/Button'
import { Paths } from '../../common/Paths'
import GraphViewModal from './components/GraphViewModal'
import EntityPageHeader from '../../components/layout/EntityPageHeader'
import Breadcrumbs from '../../components/layout/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import ObjectsPanel from './components/ObjectsPanel'
import EntityMenu from './components/EntityMenu'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { getExternalSystemUrl } from '../../common/Utils'
import { SnackbarSeverity } from '../../enums/SnackbarSeverity'
import EntitiesToGroupsMapper from '../../common/EntitiesToGroupsMapper'
import { System } from '../../enums/System'
import { MappedEntity } from '../../types/MappedEntity'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  button: {
    marginLeft: '10px',
  },
  relationshipsTitle: {
    background: theme.palette.background.default,
  },
}))

export default () => {
  const { id } = useParams()
  const { objectsModal, editMode, groups, related, graphModalOpen, debug } = useSelector((state: any) => ({
    objectsModal: state.objectsModal,
    editMode: state.editMode,
    groups: state.entity.groups,
    related: state.entity.related,
    graphModalOpen: state.graphModalOpen,
    debug: state.debug,
  }))
  const classes = useStyles()
  const [entity, setEntity] = useState<MappedEntity>({ entityId: '' })
  const [expanded, setExpanded] = useState<number | boolean>(false)
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  const fetchEntity = useCallback(() => {
    getEntity(id, true)
      .then(res => {
        let mappedEntity = EntityToRowMapper(res.data)
        setEntity(mappedEntity)
        let { groups, related } = EntitiesToGroupsMapper(res.data.edges, mappedEntity, debug)
        if (EXCLUDE_PARENT_ACCOUNTS) {
          let parentAccountsGroup = groups.find(group => group.label.toLowerCase() === 'parent accounts')
          groups = groups.filter(group => group !== parentAccountsGroup)
          if (parentAccountsGroup && parentAccountsGroup.rows[0]) {
            mappedEntity.parentAccount = parentAccountsGroup.rows[0]
          } else {
            mappedEntity.parentAccount = HIDDEN_FIELD
          }
        }

        store.dispatch(updateEntityGroups(groups))
        store.dispatch(updateEntityRelated(related))
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
        store.dispatch(openSnackbar({ message: 'Error while fetching entity', severity: SnackbarSeverity.ERROR }))
        setLoading(false)
      })
  }, [id, debug])

  const refreshEntity = () => {
    fetchEntity()
  }

  useEffect(() => {
    setEntity({ entityId: '' })
    setExpanded(false)
    setLoading(true)
    store.dispatch(updateEntityRelated([]))
    store.dispatch(updateEntityGroups([]))
    store.dispatch(toggleEditMode(false))
    fetchEntity()
  }, [fetchEntity])

  const handleChange = (index: number) => (event: any, isExpanded: boolean) => {
    store.dispatch(updateSelectedEntities([]))
    setExpanded(isExpanded ? index : false)
  }

  const deleteRows = (entityIds: string[]) => {
    let updatedRows = groups.map((group: any) => {
      let updatedGroup = { ...group }
      updatedGroup.rows = updatedGroup.rows.filter((entityRow: any) => {
        return !entityIds.find(entityId => entityId === entityRow.entityId)
      })
      return updatedGroup
    })
    store.dispatch(updateEntityGroups(updatedRows))
  }

  const deleteRelated = (entityId: string) => {
    const udpatedRelated = related.filter((entity: any) => entity.entityId !== entityId)
    store.dispatch(updateEntityRelated(udpatedRelated))
    store.dispatch(openSnackbar({ message: 'Object link deleted successfully', severity: SnackbarSeverity.SUCCESS }))
  }

  const handleEditClick = () => {
    store.dispatch(toggleEditMode(null))
  }

  const handleAddRelationClick = () => {
    store.dispatch(openObjectsModal({ type: EDGE, entity }))
  }

  const handleViewGraphClick = () => {
    store.dispatch(toggleGraphModal(true))
  }

  const handleGoToLogsClick = () => {
    history.push(Paths.logs(entity.entityId))
  }

  const menuItems = [{ label: 'Logs', action: handleGoToLogsClick }]

  const handleOpenSystemClick = () => {
    const url = getExternalSystemUrl(entity)
    if (url) {
      window.open(url)
    }
  }

  return (
    <>
      <EntitySearchModal open={objectsModal.open} refreshEntity={refreshEntity} />
      <GraphViewModal open={graphModalOpen} entity={entity} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs entity={entity} current={entity.source === System.SERVICE_NOW ? entity.entityId : entity.name} />
        </Grid>

        <Grid item xs={12}>
          <EntityPageHeader entity={entity} related={related} deleteRelated={deleteRelated}>
            {entity.type && (
              <>
                {!editMode && (
                  <>
                    {(entity.source === System.SERVICE_NOW || entity.source === System.SALESFORCE) && (
                      <Button onClick={handleOpenSystemClick} disableElevation className={classes.button} color="default" variant="contained">
                        View in {SYSTEMS_LABELS[entity.source]}{' '}
                        <OpenInNewIcon style={{ fontSize: '12px', marginLeft: '5px', verticalAlign: 'middle' }} />
                      </Button>
                    )}

                    <Button onClick={handleViewGraphClick} disableElevation className={classes.button} color="primary" variant="contained">
                      View Graph
                    </Button>
                  </>
                )}
                {editMode && (
                  <Button onClick={handleAddRelationClick} disableElevation className={classes.button} color="secondary" variant="contained">
                    + Relationship
                  </Button>
                )}
                <Button
                  onClick={handleEditClick}
                  disableElevation
                  className={classes.button}
                  color={editMode ? 'primary' : 'secondary'}
                  variant="contained"
                >
                  {!editMode ? 'Edit' : 'Exit Edit Mode'}
                </Button>
                {!editMode && <EntityMenu items={menuItems} />}
              </>
            )}
          </EntityPageHeader>
        </Grid>

        <Grid item xs={12}>
          {loading && (
            <Typography align={'center'} component={'div'}>
              <CircularProgress />
            </Typography>
          )}
          {!loading && groups.length > 0 && (
            <Card>
              <CardContent className={classes.relationshipsTitle}>
                {!loading && groups.length > 0 && <Typography variant={'h6'}>Relationships</Typography>}
              </CardContent>
              {groups.map((group: any, i: number) => (
                <ObjectsPanel
                  key={i}
                  group={group}
                  expanded={expanded === i}
                  handleChange={handleChange}
                  deleteRows={deleteRows}
                  entity={entity}
                  index={i}
                />
              ))}
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  )
}
