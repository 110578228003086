import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import EntityTableHead from './EntityTableHead'
import EntityTableToolbar from './EntityTableToolbar'
import { useSelector } from 'react-redux'
import { deleteEdge } from '../../../../services/Api'
import { openSnackbar, store, toggleDeletingEntities, updateSelectedEntities } from '../../../../common/Store'
import { SYSTEMS_LABELS } from '../../../../common/Constants'
import FieldValue from '../../../../components/FieldValue'
import EntityTableFields from './EntityTableFields'
import EntityTableMenu from './EntityTableMenu'
import { SnackbarSeverity } from '../../../../enums/SnackbarSeverity'
import { System } from '../../../../enums/System'
import { EdgeDirection } from '../../../../enums/EdgeDirection'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    width: '100%',
  },
}))

export default ({ rows, type, entity, deleteRows }) => {
  const classes = useStyles()
  const { selected, editMode, debug } = useSelector(state => ({
    selected: state.selectedEntities,
    editMode: state.editMode,
    debug: state.debug,
  }))
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.entityId)
      store.dispatch(updateSelectedEntities(newSelecteds))
      return
    }
    store.dispatch(updateSelectedEntities([]))
  }

  const handleClick = (event, entityId) => {
    // FIXME store the entity object instead of the entityId to avoid searching it again when deleting
    const selectedIndex = selected.indexOf(entityId)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, entityId)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    store.dispatch(updateSelectedEntities(newSelected))
  }

  const handleDeleteClick = () => {
    let deleteEntities = []
    let deleteRequests = []
    let invalidRelation = false
    for (let i = 0; i < selected.length; i++) {
      const entityId = selected[i]
      const entityRow = rows.find(entity => entity.entityId === entityId)
      if (entityRow.edgeSource !== System.POLARA) {
        invalidRelation = true
        deleteEntities = []
        deleteRequests = []
        break
      } else {
        if (entityRow.edgeDirection === EdgeDirection.INCOMING) {
          deleteRequests.push(deleteEdge(entityRow.entityId, entityRow.edgeType, entity.entityId))
        } else {
          deleteRequests.push(deleteEdge(entity.entityId, entityRow.edgeType, entityRow.entityId))
        }
        deleteEntities.push(entityRow.entityId)
      }
    }

    if (invalidRelation) {
      store.dispatch(openSnackbar({ message: `Only ${SYSTEMS_LABELS[System.POLARA]} relations can be deleted`, severity: SnackbarSeverity.WARNING }))
    } else {
      store.dispatch(toggleDeletingEntities(true))
      Promise.all(deleteRequests)
        .then(values => {
          store.dispatch(
            openSnackbar({ message: `Relation${deleteRequests.length > 1 ? 's' : ''} deleted successfully`, severity: SnackbarSeverity.SUCCESS })
          )
          deleteRows(deleteEntities)
        })
        .finally(() => {
          store.dispatch(toggleDeletingEntities(false))
          store.dispatch(updateSelectedEntities([]))
        })
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = name => selected.indexOf(name) !== -1

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table className={classes.table} size="small">
          <EntityTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            type={type}
            columns={EntityTableFields}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.entityId)

                return (
                  <TableRow role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={index} selected={isItemSelected}>
                    {editMode && (
                      <TableCell padding="checkbox">
                        <Checkbox onClick={event => handleClick(event, row.entityId)} checked={isItemSelected} />
                      </TableCell>
                    )}

                    {(EntityTableFields[type] || EntityTableFields['default']).map((field, columnIndex) => (
                      <TableCell
                        align={field.numeric ? 'right' : 'left'}
                        key={columnIndex}
                        padding={editMode ? (field.disablePadding ? 'none' : 'default') : 'default'}
                      >
                        <FieldValue id={field.id} value={row[field.id]} props={{ entity: row }} />
                      </TableCell>
                    ))}
                    <TableCell align="right">
                      {debug && 'edgeDirection: ' + row.edgeDirection}
                      <EntityTableMenu entity={row} />
                    </TableCell>
                  </TableRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 32 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container>
        <Grid item xs={6}>
          <EntityTableToolbar numSelected={selected.length} handleDeleteClick={handleDeleteClick} />
        </Grid>
        <Grid item xs={6}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </div>
  )
}
