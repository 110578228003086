import axios from 'axios'
import Config from '../config/Config'

const API_BASE_URL = Config.api.apiBaseUrl
const API_MOCK_URL = Config.api.apiMockUrl

export const getSystems = () => {
  return axios.get(`${API_BASE_URL}/systems`)
}

export const getEntity = (id, edges = false) => {
  return axios.get(`${API_BASE_URL}/entities/${id}${edges ? '?edges=true' : ''}`)
}

export const getNotifications = () => {
  return axios.get(`${API_MOCK_URL}/notifications`)
}

export const searchEntities = (text, params) => {
  return axios.get(`${API_BASE_URL}/entities/search/${text}`, { params })
}

export const addEdge = request => {
  return axios.post(`${API_BASE_URL}/edges`, request)
}

export const deleteEdge = (fromId, edgeType, toId) => {
  return axios.delete(`${API_BASE_URL}/edges/${fromId}/${edgeType}/${toId}`)
}

export const addEntity = request => {
  return axios.post(`${API_BASE_URL}/entities`, request)
}

export const addLink = request => {
  return axios.post(`${API_BASE_URL}/edges/link`, request)
}

export const getEvents = () => {
  return axios.get(`${API_MOCK_URL}/events`)
}

export const getEvent = id => {
  return axios.get(`${API_MOCK_URL}/events/${id}`)
}
