import Grid from '@material-ui/core/Grid'
import Breadcrumbs from '../../components/layout/Breadcrumbs'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card/Card'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import FieldLabel from '../../components/FieldLabel'
import FieldValue from '../../components/FieldValue'
import JSONPretty from 'react-json-pretty'
import 'react-json-pretty/themes/monikai.css'
import './EventPage.css'
import { getEvent } from '../../services/Api'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import { Collapse, TableCell } from '@material-ui/core'
import { FunctionStatus } from '../../enums/FunctionStatus'

const fields = ['date', 'source', 'name', 'status']

const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
  },
}))

export default () => {
  const theme = useTheme()
  const classes = useStyles()
  const { id } = useParams()
  const [event, setEvent] = useState<any>([])

  useEffect(() => {
    getEvent(id).then((res: any) => setEvent(res.data))
  }, [id])

  return (
    <>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12}>
          <Breadcrumbs event={event} />
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={8}>
                  <Typography variant={'h5'}>{`Event #${event.id || ''}`}</Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Grid container>
                {fields.map(field => (
                  <Grid item xs={3} key={field}>
                    <Typography variant={'body2'} component={'div'} style={{ fontWeight: 'bold' }}>
                      <FieldLabel id={field} type="event" />
                    </Typography>
                    <FieldValue id={field} value={event[field]} />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Collapse in={(event.functions || []).length > 0}>
            <Card>
              <CardContent>
                <Typography variant={'h6'}>Functions</Typography>
              </CardContent>
              <Divider />
              <TableContainer>
                <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(event.functions || []).map((func: any, i: number) => (
                      <TableRow key={i}>
                        <TableCell>{func.name}</TableCell>
                        <TableCell style={{ color: func.status === FunctionStatus.SUCCESS ? theme.palette.success.main : theme.palette.error.main }}>
                          {func.status}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Collapse>
        </Grid>
        <Grid item xs={8}>
          <Collapse in={event.payload && true}>
            <Card>
              <CardContent>
                <Typography variant={'h6'}>Payload</Typography>
              </CardContent>
              <Divider />
              <CardContent>
                <JSONPretty id="json-payload" data={event.payload}></JSONPretty>
              </CardContent>
            </Card>
          </Collapse>
        </Grid>
      </Grid>
    </>
  )
}
