import CardContent from '@material-ui/core/CardContent'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import { SYSTEMS_LABELS } from '../../common/Constants'
// @ts-ignore
import Pluralize from 'react-pluralize'
import Card from '@material-ui/core/Card/Card'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import Fade from '@material-ui/core/Fade/Fade'
import { Link } from 'react-router-dom'
import { Paths } from '../../common/Paths'
import './Breadcrumbs.css'

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    textTransform: 'capitalize',
  },
  cardContent: {
    paddingTop: '5px',
    paddingBottom: '5px !important',
  },
}))

export default ({ entity, event, current }: { entity?: any; event?: any; current?: any }) => {
  const classes = useStyles()
  let content

  if (entity) {
    content = (
      <Breadcrumbs className={classes.breadcrumbs}>
        {!entity.type && <Skeleton width={160} />}
        {entity.type && <Typography>{SYSTEMS_LABELS[entity.source]}</Typography>}
        {entity.type && (
          <Typography>
            <Pluralize singular={entity.type} showCount={false} count={2} />
          </Typography>
        )}
        {entity.type && <Typography color="textPrimary">{current}</Typography>}
      </Breadcrumbs>
    )
  } else if (event) {
    content = (
      <Breadcrumbs className={classes.breadcrumbs}>
        <Link to={Paths.events}>Events</Link>
        <Typography>{`#${event.id || ''}`}</Typography>
      </Breadcrumbs>
    )
  } else {
    content = (
      <Breadcrumbs className={classes.breadcrumbs}>
        <Typography>{current}</Typography>
      </Breadcrumbs>
    )
  }

  if (entity) {
    return (
      <Fade in={Boolean(entity.type)} timeout={200}>
        <Card>
          <CardContent className={classes.cardContent}>{content}</CardContent>
        </Card>
      </Fade>
    )
  } else if (event) {
    return (
      <Card>
        <CardContent className={classes.cardContent}>{content}</CardContent>
      </Card>
    )
  } else {
    return (
      <Card>
        <CardContent className={classes.cardContent}>{content}</CardContent>
      </Card>
    )
  }
}
