// @ts-ignore
import faker from 'faker'
import { FunctionStatus } from '../../enums/FunctionStatus'
import { EventStatus } from '../../enums/EventStatus'
import { System } from '../../enums/System'

const S3_payload = {
  source: 'event-hub',
  groupId: 'afaebac0-37df-11ea-955e-5d7b0daaf52b',
  event: 's3:polara-test-pubsub-bucket-jmt',
  body: {
    Records: [
      {
        eventVersion: '2.1',
        eventSource: 'aws:s3',
        awsRegion: 'us-east-2',
        eventTime: '2020-05-01T18:27:16.049Z',
        eventName: 'ObjectCreated:Put',
        userIdentity: {
          principalId: 'aws:aroau6dpbqxfgljbku6ac:jtula@aureum.us',
        },
        requestParameters: {
          sourceIPAddress: '181.167.241.92',
        },
        responseElements: {
          'x-amz-request-id': '14EF19535CF11ED8',
          'x-amz-id-2': 'ZUIf8XeIVaIR1uI6knlPgLjOEx6FDH8/EdgmwJXeNKfiZCMSUGK9n7qx3uGXjqhFR5xkADh3OiMjnOTduRRI2mVF68KKTitz',
        },
        s3: {
          s3SchemaVersion: '1.0',
          configurationId: 'polara-x-eventhub-listener',
          bucket: {
            name: 'polara-test-pubsub-bucket-jmt',
            ownerIdentity: {
              principalId: 'ALER1LYAB05BM',
            },
            arn: 'arn:aws:s3:::polara-test-pubsub-bucket-jmt',
          },
          object: {
            key: 'meeting_notes.txt',
            size: 1369,
            eTag: '68e82cf30f614b6eb0a42b5ba5033f8b',
            sequencer: '005EAC6A04C923AFB3',
          },
        },
      },
    ],
  },
}

const System_payload = [
  {
    source: 'servicenow',
    event: 'sn_customerservice_case',
    body: {
      sys_id: '3291bac7db5450107e032a9a48961953',
      priority: '1',
      xxx_display_value: 'CS0001018',
      xxx_operation: 'update',
      xxx_table_name: 'sn_customerservice_case',
    },
  },
  {
    event: 'servicenow.case',
    source: 'polara',
    body: {
      action: 'UPDATE',
      entity: {
        entityId: '3291bac7db5450107e032a9a48961953',
        type: 'Case',
        name: 'New Case',
        source: 'servicenow',
        properties: { subject: "volume up doesn't work", priority: '1', status: '1' },
        entityType: 'ServiceNowEntity',
      },
    },
  },
  {
    event: 'case',
    source: 'salesforce',
    body: { operation: 'UPDATE', ids: ['5003j00001ffXz8AAE'], values: { LastModifiedDate: '2020-04-25T14:34:19.000Z' } },
  },
  {
    event: 'servicenow.case',
    source: 'polara',
    body: {
      action: 'UPDATE',
      entity: {
        entityId: '3291bac7db5450107e032a9a48961953',
        type: 'Case',
        name: 'New Case',
        source: 'servicenow',
        properties: { subject: "volume up doesn't work sometimes", priority: '1', status: '1' },
        entityType: 'ServiceNowEntity',
      },
    },
  },
]

const generateEvents = () => {
  let events: any = []
  for (let i = 1; i < 587; i++) {
    events.push({
      id: i * 23,
      date: faker.date.recent(30),
      source: faker.random.arrayElement([System.SALESFORCE, System.SERVICE_NOW, 'S3']),
      name: faker.random.arrayElement(['Account', 'Case', 'Asset']),
      status: faker.random.arrayElement(Object.values(EventStatus)),
    })
    events = events.map((event: any) => {
      if (event.source === 'S3') {
        event.name = faker.random.arrayElement(['pubsub-bucket-target-jmt', 'polara-test-pubsub-bucket-jmt'])
        event.payload = S3_payload.body
        event.functions = [{ name: 's3-event-handler', status: 'Success' }]
      } else {
        event.payload = System_payload[faker.random.number({ min: 0, max: System_payload.length - 1 })].body
        event.functions = [
          { name: 'create-slack-post', status: FunctionStatus.SUCCESS },
          { name: 'send-to-edge', status: FunctionStatus.SUCCESS },
          { name: 'salesforce-asset-to-service-now', status: FunctionStatus.FAIL },
          { name: 'servicenow-case-to-salesforce', status: FunctionStatus.SUCCESS },
        ]
      }
      return event
    })
  }
  return events
}

const events = generateEvents()

export default events
