import { Server } from 'miragejs'
import Config from '../config/Config'
import faker from 'faker'
import events from './reponses/events'

export function makeMockApiServer() {
  let server = new Server({
    routes() {
      this.namespace = 'mock-api'
      this.timing = 600
      this.logging = process.env.NODE_ENV === 'development'

      const generateNotifications = () => {
        const notifications = []
        for (let i = 1; i < faker.random.number({ min: 3, max: 10 }); i++) {
          notifications.push({
            id: i,
            text: 'AT&T Account has been associated with AT&T Customer',
            date: faker.date.recent(),
            entityId: '0013j00002cTKWIAA4',
          })
        }
        return notifications
      }

      this.get('/notifications', () => generateNotifications())

      this.get('/events', () => events)

      this.get('/events/:id', (schema, request) => {
        return events.find(event => event.id === parseInt(request.params.id))
      })

      // Skip urls if they don't include mock-api
      this.passthrough(request => !request.url.includes(Config.api.apiMockUrl))
    },
  })

  return server
}
