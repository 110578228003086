import { configureStore, createSlice } from '@reduxjs/toolkit'
import { LINK, OBJECT_TYPES } from './Constants'

const appSlice = createSlice({
  name: 'app',
  initialState: {
    editMode: false,
    objectsModal: {
      entity: {},
      open: false,
      type: LINK,
    },
    selectedEntities: [],
    objectsModalOpen: false,
    graphModalOpen: false,
    deletingEntities: false,
    systems: [],
    objectTypes: OBJECT_TYPES,
    snackbar: {
      open: false,
      message: '',
    },
    entity: {
      groups: [],
      related: [],
    },
    debug: false,
  },
  reducers: {
    toggleEditMode: (state, action) => {
      state.editMode = action.payload === undefined || action.payload === null ? !state.editMode : action.payload
      if (!state.editMode) {
        state.selectedEntities = []
      }
    },
    toggleDeletingEntities: (state, action) => {
      state.deletingEntities = action.payload === undefined ? !state.deletingEntities : action.payload
    },
    openObjectsModal: (state, action) => {
      state.objectsModal = {
        open: true,
        ...action.payload,
      }
    },
    closeObjectsModal: state => {
      state.objectsModal = {
        ...state.objectsModal,
        open: false,
      }
    },
    storeSystems: (state, action) => {
      state.systems = action.payload
    },
    openSnackbar: (state, action) => {
      state.snackbar = { ...action.payload, open: true }
    },
    closeSnackbar: state => {
      state.snackbar = { ...state.snackbar, open: false }
    },
    updateEntityGroups: (state, action) => {
      state.entity.groups = action.payload
    },
    updateEntityRelated: (state, action) => {
      state.entity.related = action.payload
    },
    toggleGraphModal: (state, action) => {
      state.graphModalOpen = action.payload === undefined ? !state.graphModalOpen : action.payload
    },
    updateSelectedEntities: (state, action) => {
      state.selectedEntities = action.payload
    },
    enableDebug: state => {
      state.debug = true
    },
  },
})

const store = configureStore({ reducer: appSlice.reducer })
export { store }
export const {
  toggleEditMode,
  openObjectsModal,
  storeSystems,
  openSnackbar,
  closeSnackbar,
  toggleDeletingEntities,
  closeObjectsModal,
  updateEntityGroups,
  updateEntityRelated,
  toggleGraphModal,
  updateSelectedEntities,
  enableDebug,
} = appSlice.actions
