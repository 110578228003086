import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card/Card'
import { CardContent, Fade } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import React, { useState } from 'react'

export default () => {
  const [showMessage, setShowMessage] = useState<boolean>(false)
  setTimeout(() => {
    setShowMessage(true)
  }, 500)

  return (
    <Grid container justify="center" spacing={0}>
      <Grid item xs={4}>
        <Fade in={showMessage}>
          <Card elevation={9}>
            <CardContent>
              <Typography variant={'body1'}>
                <ArrowUpwardIcon style={{ verticalAlign: 'middle', marginRight: '15px' }} />
                Use the above input to search for objects
              </Typography>
            </CardContent>
          </Card>
        </Fade>
      </Grid>
    </Grid>
  )
}
