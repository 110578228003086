import React, { useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import { closeObjectsModal, openSnackbar, store } from '../../common/Store'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { addEntity, searchEntities } from '../../services/Api'
import EntitySearchTable from './EntitySearchTable'
import { useSelector } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem'
import { LINK, NEW_ENTITY_TYPES, SYSTEMS_LABELS } from '../../common/Constants'
import LoadingButton from '../LoadingButton'
import Typography from '@material-ui/core/Typography'
import { capitalize } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import { SnackbarSeverity } from '../../enums/SnackbarSeverity'

export default ({ open, refreshEntity }) => {
  const ALL = 'All'
  const systems = useSelector(state => state.systems)
  const objectTypes = useSelector(state => state.objectTypes)
  const objectsModal = useSelector(state => state.objectsModal)
  const entity = useSelector(state => state.objectsModal.entity)
  const [entities, setEntities] = useState([])
  const [text, setText] = useState('')
  const [type, setType] = useState(ALL)
  const [system, setSystem] = useState(ALL)
  const [showAddEntity, setShowAddEntity] = useState(false)
  const [newEntityName, setNewEntityName] = useState('')
  const [newEntityType, setNewEntityType] = useState('')
  const [searchLoading, setSearchLoading] = useState(false)
  const [createEntityLoading, setCreateEntityLoading] = useState(false)
  const [searchedOnce, setSearchedOnce] = useState(false)

  const handleTextChange = event => {
    setText(event.target.value)
  }

  const handleTypeChange = event => {
    setType(event.target.value)
  }

  const handleSystemChange = event => {
    setSystem(event.target.value)
  }

  const handleNewEntityTypeChange = event => {
    setNewEntityType(event.target.value)
  }

  const handleNewEntityNameChange = event => {
    setNewEntityName(event.target.value)
  }

  const handleCloseClick = () => {
    store.dispatch(closeObjectsModal())
    reset()
  }

  const handleSearchClick = () => {
    setSearchLoading(true)
    searchEntities(text, { type: type !== ALL ? type : null, system: system !== ALL ? system : null })
      .then(res => {
        if (res.data.length > 0) {
          setEntities(res.data)
        } else {
          setEntities([{ name: 'No objects found.' }])
        }
      })
      .finally(() => {
        setSearchedOnce(true)
        setSearchLoading(false)
      })
  }

  const reset = () => {
    setText('')
    setType(ALL)
    setSystem(ALL)
    setEntities([])
    setShowAddEntity(false)
    setSearchedOnce(false)
  }

  const handleClearClick = () => {
    reset()
  }

  const handleAddNewClick = () => {
    setShowAddEntity(true)
  }

  const handleCancelAddNewClick = () => {
    setShowAddEntity(false)
  }

  const handleCreateEntityClick = () => {
    setCreateEntityLoading(true)
    addEntity({ name: newEntityName, type: newEntityType })
      .then(res => {
        store.dispatch(openSnackbar({ message: 'Object created successfully', severity: SnackbarSeverity.SUCCESS }))
        setEntities([res.data])
      })
      .finally(() => {
        setCreateEntityLoading(false)
      })
  }

  const createFormValid = () => {
    return newEntityName.trim() !== '' && newEntityType !== ''
  }

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="lg" fullWidth aria-labelledby="execution-dialog-title" open={open}>
      <DialogTitle id="execution-dialog-title">
        {`Add new ${objectsModal.type === LINK ? 'Link' : 'Relation'} to ${entity.name} `}
        <Typography variant={'caption'}>
          {SYSTEMS_LABELS[entity.source]} {capitalize(entity.type || '')}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              disabled={showAddEntity || searchLoading}
              fullWidth
              onChange={handleTextChange}
              size={'small'}
              label="Search objects"
              variant="outlined"
              value={text}
              onKeyPress={ev => {
                if (ev.key === 'Enter') {
                  handleSearchClick()
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              disabled={showAddEntity || searchLoading}
              fullWidth
              select
              onChange={handleTypeChange}
              size={'small'}
              label="Type"
              variant="outlined"
              value={type}
            >
              <MenuItem value={ALL}>{ALL}</MenuItem>
              {objectTypes.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <TextField
              disabled={showAddEntity || searchLoading}
              fullWidth
              select
              onChange={handleSystemChange}
              size={'small'}
              label="System"
              variant="outlined"
              value={system}
            >
              <MenuItem value={ALL}>{ALL}</MenuItem>
              {systems.map(option => (
                <MenuItem key={option} value={option}>
                  {SYSTEMS_LABELS[option]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <LoadingButton
              disabled={showAddEntity || text === ''}
              loading={searchLoading}
              style={{ marginRight: '10px' }}
              onClick={handleSearchClick}
            >
              Search
            </LoadingButton>
            <Button
              disabled={showAddEntity || searchLoading || text === ''}
              style={{ marginRight: '10px' }}
              variant="outlined"
              color={'default'}
              onClick={handleClearClick}
            >
              Clear
            </Button>
            <Button disabled={showAddEntity || searchLoading} variant="contained" disableElevation color={'secondary'} onClick={handleAddNewClick}>
              Create Object
            </Button>
          </Grid>
          <Collapse in={showAddEntity}></Collapse>
          {showAddEntity && (
            <>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  disabled={createEntityLoading}
                  onChange={handleNewEntityNameChange}
                  size={'small'}
                  label="Object name"
                  variant="outlined"
                  value={newEntityName}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  disabled={createEntityLoading}
                  select
                  onChange={handleNewEntityTypeChange}
                  size={'small'}
                  label="Object type"
                  variant="outlined"
                  value={newEntityType}
                >
                  {NEW_ENTITY_TYPES.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <LoadingButton
                  loading={createEntityLoading}
                  disabled={!createFormValid()}
                  style={{ marginRight: '10px' }}
                  onClick={handleCreateEntityClick}
                >
                  Create
                </LoadingButton>
                <Button disabled={createEntityLoading} variant="contained" disableElevation color={'default'} onClick={handleCancelAddNewClick}>
                  Cancel
                </Button>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Collapse in={searchedOnce}>
              <EntitySearchTable rows={entities} entity={entity} refreshEntity={refreshEntity} updateRows={setEntities} />
            </Collapse>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={searchLoading || createEntityLoading} onClick={handleCloseClick} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
