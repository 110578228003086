import React from 'react'

const FieldLabels: any = {
  generic: [
    { id: 'entityId', label: 'Entity ID' },
    { id: 'name', label: 'Name' },
    { id: 'type', label: 'Type' },
    { id: 'source', label: 'Source' },
  ],
  account: [
    { id: 'entityId', label: 'Entity ID' },
    { id: 'name', label: 'Name' },
    { id: 'accountNumber', label: 'Number' },
    { id: 'description', label: 'Description' },
    { id: 'type', label: 'Type' },
    { id: 'source', label: 'Source' },
    { id: 'edgeDirection', label: 'Relation' },
    { id: 'parentAccount', label: 'Parent Account' },
  ],
  case: [
    { id: 'entityId', label: 'Entity ID' },
    { id: 'name', label: 'Name' },
    { id: 'caseNumber', label: 'Number' },
    { id: 'subject', label: 'Subject' },
    { id: 'createdDate', label: 'Created Date' },
    { id: 'priority', label: 'Priority' },
    { id: 'status', label: 'Status' },
    { id: 'source', label: 'Source' },
    { id: 'edgeDirection', label: 'Relation' },
  ],
  contact: [
    { id: 'name', label: 'Name' },
    { id: 'entityId', label: 'Entity ID' },
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
    { id: 'email', label: 'Email' },
    { id: 'source', label: 'Source' },
    { id: 'serviceId', label: 'Service ID' },
    { id: 'edgeDirection', label: 'Relation' },
  ],
  asset: [
    { id: 'name', label: 'Name' },
    { id: 'entityId', label: 'Entity ID' },
    { id: 'name', label: 'Name' },
    { id: 'serialNumber', label: 'Serial Number' },
    { id: 'source', label: 'Source' },
    { id: 'edgeDirection', label: 'Relation' },
  ],
  customer: [
    { id: 'name', label: 'Name' },
    { id: 'entityId', label: 'Entity ID' },
    { id: 'name', label: 'Name' },
    { id: 'source', label: 'Source' },
    { id: 'edgeDirection', label: 'Relation' },
  ],
  log: [
    { id: 'event', label: 'Event' },
    { id: 'id', label: 'ID' },
    { id: 'createdDate', label: 'Created On' },
    { id: 'createdBy', label: 'Created By' },
  ],
  event: [
    { id: 'date', label: 'Date' },
    { id: 'source', label: 'Source' },
    { id: 'name', label: 'Name' },
    { id: 'payload', label: 'Payload' },
    { id: 'status', label: 'Status' },
  ],
}

export default ({ id, type = 'generic' }: { id: string; type?: string }) => {
  let fieldLabel = `FieldLabel: ${id} not found for ${type}`
  const field = (FieldLabels[type.toLowerCase()] || FieldLabels.generic).find((f: any) => f.id === id)
  if (field) {
    fieldLabel = field.label
  }
  return <>{fieldLabel}</>
}
