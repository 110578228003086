import { Link } from 'react-router-dom'
import { Paths } from '../common/Paths'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Moment from 'react-moment'
import { DATE_TIME_FORMAT, EMPTY_FIELD, HIDDEN_FIELD, LINK, LOG_DATE_TIME_FORMAT } from '../common/Constants'
import React from 'react'
import { capitalize, useTheme } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { useSelector } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import { openObjectsModal, store } from '../common/Store'
import { makeStyles } from '@material-ui/core/styles'
import { EdgeDirection } from '../enums/EdgeDirection'
import { EdgeType } from '../enums/EdgeType'
import { EventStatus } from '../enums/EventStatus'

const useStyles = makeStyles(theme => ({
  notMapped: {
    color: theme.palette.error.light,
  },
}))

export default ({ id, value, props }: { id: string; value: any; props?: any }) => {
  const theme = useTheme()
  const editMode = useSelector<boolean>((state: any) => state.editMode)
  const classes = useStyles()

  const handleEditClick = () => {
    store.dispatch(openObjectsModal({ type: LINK, entity: props.entity }))
  }

  switch (id) {
    case 'entityId':
      return value ? (
        <Link {...props} to={{ pathname: Paths.entity(value), state: { entity: value } }}>
          {value}
          {(props || {}).target === '_blank' && <OpenInNewIcon style={{ fontSize: '12px', marginLeft: '5px', verticalAlign: 'middle' }} />}
        </Link>
      ) : (
        ''
      )

    case 'serviceId':
      return value ? (
        value
      ) : (
        <>
          <span className={classes.notMapped}>Not Mapped</span>
          {editMode && (
            <IconButton onClick={handleEditClick} size={'small'} aria-label="edit" style={{ marginLeft: '5px' }}>
              <EditIcon style={{ fontSize: '18px', visibility: editMode ? 'visible' : 'hidden' }} />
            </IconButton>
          )}
        </>
      )

    case 'createdDate':
      return value ? <Moment format={DATE_TIME_FORMAT}>{value}</Moment> : EMPTY_FIELD

    case 'date':
      return value ? <Moment format={LOG_DATE_TIME_FORMAT}>{value}</Moment> : EMPTY_FIELD

    case 'source':
    case 'type':
      return capitalize(value || EMPTY_FIELD)

    case 'edgeDirection':
      let label
      if (props.entity.edgeType === EdgeType.LINKED) {
        label = 'Linked'
      } else {
        label = value === EdgeDirection.INCOMING ? 'Child Account' : 'Parent Account'
      }
      return label

    case 'payload':
      return (
        <Link {...props} to={{ pathname: Paths.event(props.event.id), state: { event: props.event } }}>
          View
        </Link>
      )

    case 'status':
      // FIXME this matches with status fields of cases
      const colors: any = {
        [EventStatus.NEW]: theme.palette.info.main,
        [EventStatus.PROCESSED]: theme.palette.success.main,
        [EventStatus.FAILED]: theme.palette.error.main,
      }
      return <span style={{ color: colors[value] }}>{value}</span>

    case 'parentAccount':
      return value ? (
        <Link {...props} to={Paths.entity(value.entityId)}>
          {value.name}
        </Link>
      ) : (
        HIDDEN_FIELD
      )

    default:
      return value || EMPTY_FIELD
  }
}
