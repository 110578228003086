import React from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  loadingButtonWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

export default ({ children, loading, disabled = false, onClick, variant = 'contained', color = 'primary', size = 'medium', style = {} }) => {
  const classes = useStyles()

  return (
    <span style={style} className={classes.loadingButtonWrapper}>
      <Button onClick={onClick} variant={variant} color={color} disabled={loading || disabled} size={size} disableElevation>
        {children}
      </Button>
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </span>
  )
}
