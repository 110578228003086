import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import EntityTable from './relationships-table/EntityTable'
import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Button from '@material-ui/core/Button'
import { store, toggleEditMode } from '../../../common/Store'
import { useSelector } from 'react-redux'
import Fade from '@material-ui/core/Fade'
import { System } from '../../../enums/System'

const ExpansionPanelDetails = withStyles({
  root: {
    padding: 0,
  },
})(MuiExpansionPanelDetails)

const ExpansionPanelSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    backgroundColor: 'rgba(0, 0, 0, .05)',
    '&$expanded': {
      minHeight: 56,
      backgroundColor: '#fff',
    },
    paddingLeft: '16px',
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary)

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    borderLeft: 'none ',
    borderRight: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel)

const useStyles = makeStyles(theme => ({
  title: {
    textTransform: 'capitalize',
    fontSize: '16px',
    color: theme.palette.grey['700'],
  },
  count: {
    fontSize: '14px',
    width: '50px',
    borderRadius: '2px',
    color: theme.palette.primary.main,
    display: 'inline-block',
    textAlign: 'right',
    padding: '0 5px',
  },
  wrapper: {
    height: '100%',
  },
  logo: {
    height: '18px',
    margin: '5px 0 0 0',
  },
}))

export default ({ group, entity, expanded, handleChange, deleteRows, index }) => {
  const classes = useStyles()
  const editMode = useSelector(state => state.editMode)
  const debug = useSelector(state => state.debug)

  const handleEditClick = e => {
    e.stopPropagation()
    store.dispatch(toggleEditMode())
  }

  return (
    <ExpansionPanel expanded={expanded} onChange={handleChange(index)} TransitionProps={{ unmountOnExit: true }}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container>
          <Grid item xs={10}>
            <div className={classes.wrapper}>
              <Typography className={classes.title} variant="h5" component="div">
                {group.label}
                {debug && (
                  <Typography
                    variant={'caption'}
                    style={{ marginLeft: '100px' }}
                  >{`entityType: ${group.type} | source: ${group.source} | edgeType: ${group.edgeType}`}</Typography>
                )}
              </Typography>
              <div>
                {group.source === System.POLARA ? (
                  <Typography variant={'caption'}>Polara</Typography>
                ) : (
                  <img alt="System logo" className={classes.logo} src={`/images/${group.source}.png`} />
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right' }}>
            <div style={{ paddingTop: '10px' }}>
              <Fade in={expanded}>
                <Button onClick={handleEditClick} variant={'contained'} disableElevation color={'secondary'} size={'small'}>
                  {editMode ? 'Exit Edit Mode' : 'Edit'}
                </Button>
              </Fade>
              <Typography className={classes.count} variant="caption">
                {group.rows.length}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <EntityTable rows={group.rows} type={group.type} entity={entity} deleteRows={deleteRows} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
