import Container from '@material-ui/core/Container'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useKeycloak } from '@react-keycloak/web'
import Config from '../../config/Config'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '70px 0 80px',
  },
}))

export default ({ children }) => {
  const classes = useStyles()
  const [keycloak] = useKeycloak()
  const [authReady, setAuthReady] = useState(false)

  keycloak.onReady = authenticated => {
    setAuthReady(true)
  }

  useEffect(() => {
    if (authReady && !keycloak.authenticated) {
      keycloak.login({ idpHint: Config.defaultKeycloakIdpAlias })
    }
  }, [authReady, keycloak])

  return (
    <Container className={classes.root} maxWidth="lg">
      {!authReady && (
        <Typography align={'center'} component={'div'}>
          <CircularProgress />
        </Typography>
      )}
      {authReady && keycloak.authenticated && children}
    </Container>
  )
}
