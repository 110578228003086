import React, { useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { makeStyles } from '@material-ui/core/styles'
import { getExternalSystemUrl } from '../../../../common/Utils'
import { SYSTEMS_LABELS } from '../../../../common/Constants'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { System } from '../../../../enums/System'

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: '15px',
  },
}))

export default ({ entity }: { entity: any }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenSystemClick = () => {
    const url = getExternalSystemUrl(entity)
    if (url) {
      window.open(url)
    }
    handleClose()
  }

  return entity.source === System.SALESFORCE || entity.source === System.SERVICE_NOW ? (
    <>
      <IconButton size={'small'} className={classes.button} aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`entity-row-menu-${entity.entityId}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        elevation={2}
      >
        <MenuItem onClick={handleOpenSystemClick} dense>
          View in {SYSTEMS_LABELS[entity.source]} <OpenInNewIcon style={{ fontSize: '12px', marginLeft: '5px', verticalAlign: 'middle' }} />
        </MenuItem>
      </Menu>
    </>
  ) : null
}
